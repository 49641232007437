<template>
  <div v-frag>
      <v-data-table
        v-model='selectedEmployees'
        :headers='tableHeaders'
        :items='visibleItems'
        :loading='isTableLoading'
        :options.sync='tableOptions'
        :server-items-length='tableTotalItems'
        :items-per-page='10'
        loading-text='Loading employees... Please wait'
        show-select
        checkboxColor='primary'
        item-key='id'
        ref="tableRef"
      >
        <template #top>
          <v-toolbar flat class='mb-6'>
            <v-row no-gutters>
              <v-col cols='7'>
                <v-toolbar-title class='font-weight-bold'>Employee Registration Stage</v-toolbar-title>
              </v-col>
              <v-col class='d-flex align-center'>
                <v-text-field
                  v-model='tableSearch'
                  class='mr-4'
                  dense
                  hide-details
                  label='Search'
                  outlined
                  prepend-inner-icon='mdi-magnify'
                  single-line
                  @input='searchEmployees'
                >
                </v-text-field>
                <v-btn
                  class='export-btn'
                  height='40'
                  elevation='0'
                  outlined
                  color='primary'
                  @click='exportStage'
                >
                  <v-icon left>mdi-import</v-icon>
                  Export Registration Stage
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <div class='filters d-flex flex-column px-4'>
            <v-toolbar-title class='font-weight-medium mb-3'>Filters</v-toolbar-title>
            <v-row>
              <v-col cols='2'>
                <v-autocomplete
                  v-model='selectedMember'
                  :items='members'
                  :menu-props='{ bottom: true, offsetY: true }'
                  item-value='id'
                  item-text='name'
                  label='Company'
                  outlined
                  clearable
                  dense
                  @change='getEmployees'
                ></v-autocomplete>
              </v-col>
              <v-col cols='2'>
                <v-autocomplete
                  v-model='selectedStage'
                  :items='registrationStages'
                  :menu-props='{ bottom: true, offsetY: true }'
                  label='Registration Stage'
                  outlined
                  clearable
                  dense
                  @change='getEmployees'
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <v-row
            v-if='selectedEmployees.length'
            class='d-flex justify-start align-center px-4 mb-6'
            justify='start'
            no-gutters
          >
            <v-col>
              <v-btn
                class='warning--text mr-2'
                medium
                outlined
                @click='openSuspendDialog(selectedEmployees)'
              >
                <v-icon left>mdi-timer-off</v-icon>
                Suspend
              </v-btn>
              <v-btn
                :loading='rescreenSelectedLoading'
                class='text-capitalize font-weight-bold mr-2'
                color='success'
                outlined
                @click='rescreenSelected'
              >
                <v-icon class='mr-2'>mdi-update</v-icon>
                Rescreen Selected
              </v-btn>
              <v-btn
                :loading='applyVoterSelectedLoading'
                class='text-capitalize font-weight-bold'
                color='accent'
                outlined
                @click='openApplyVoterAddress(selectedEmployees)'
              >
                <v-icon class='mr-2'>mdi-sync</v-icon>
                Apply Voter Registration Address
              </v-btn>
              
            </v-col>
          </v-row>
        </template>
        <template #item.fullName={item}>
          <span
            :ref='`list-item${item.id}`'
            class='font-weight-medium full-name'
          >
            {{ item.fullName }}
          </span>
        </template>
        <template #item.ineligible={item}>
          <i v-if="!item.ineligible" style="font-size: 20px" class="w-2 mdi-checkbox-marked green--text mdi"></i>
          <i v-else  style="font-size: 20px" class="mdi mdi-close-circle red--text"></i>
        </template>
        <template #item.fullMailAddress={item}>
          <span v-html='item.fullMailAddress'></span>
        </template>
        <template #item.fullVoterAddress={item}>
          <span v-html='item.fullVoterAddress'></span>
        </template>
        <template #item.registrationStage={item}>
          <select
            :value="item.registrationStage.value || 1"
            @change="e => setRegistrationStatus(item.id, e.target.value)"
            :disabled="stageLoading && changeableEmployeeId === item.id"
            style="max-width: 250px; border: 1px solid #c4c4c4; border-radius: 4px; padding: 5px;"
          >
            <option v-for="stage in registrationStages" :key="stage.value" :value="stage.value">
              {{ stage.text }}
            </option>
          </select>
        </template>
        <template #item.actions={item}>
          <div class="d-flex flex-row flex-wrap">
          <button @click="openEditDialog(item.id)" class="pa-2 ma-1 primary white--text rounded-sm d-flex justify-center align-center">
              <i style="line-height:20px; font-size: 20px" class="mdi mdi-pencil"></i>
            </button>
            <button @click="openSuspendDialog([item])" class="pa-2 ma-1 warning white--text rounded-sm d-flex justify-center align-center">
              <i style="line-height:20px; font-size: 20px" class="mdi mdi-timer-off"></i>
            </button>
            <button @click="updateEmployeeWithRescreen(item)" class="pa-2 ma-1 success white--text rounded-sm d-flex justify-center align-center">
              <img v-if="isEmployeeUpdatingWithRescreen && changeableEmployeeId === item.id" width="20px" height="20px" :src="loader" alt="loader">
              <i v-else style="line-height:20px; font-size: 20px" class="mdi mdi-update"></i>
            </button>
            <button @click="openApplyVoterAddress([item])" class="pa-2 ma-1 accent white--text rounded-sm d-flex justify-center align-center" title="Apply Voter Registration Address">
              <i style="line-height:20px; font-size: 20px" class="mdi mdi-sync"></i>
            </button>
          </div>

        </template>
      </v-data-table>
    
    <CreateEmployeeDialog
      :is-open='isEditDialogOpen'
      :is-edit='true'
      :selected-employee='selectedEmployee'
      :is-updating-without-rescreen='isEmployeeUpdatingWithoutRescreen'
      :is-updating-with-rescreen='isEmployeeUpdatingWithRescreen'
      @update-without-rescreen='updateEmployeeWithoutRescreen'
      @update-with-rescreen='updateEmployeeWithRescreen'
      @close='isEditDialogOpen = false'
    />
    <SuspendItem
      :items='itemsToSuspend'
      :is-open='isSuspendDialogOpen'
      title='Suspend Employee'
      subtitle='employee'
      @submit='suspendEmployees'
      @close='isSuspendDialogOpen = false'
    />
    <Rescreen
      v-if='isRescreenDialogOpen'
      :is-open='isRescreenDialogOpen'
      :main-items='rescreenData'
      @update='updateAfterResolve'
      @close='isRescreenDialogOpen = false'
    />
    <ApplyVoterRegistration
      :is-open='isApplyVoterAddressDialogOpen'
      @submit='processOverwriteVoterAddress'
      @close='isApplyVoterAddressDialogOpen = false'
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { HTTP } from '../../../http-common'
import SuspendItem from '@/components/dialogs/SuspendItem.vue'
import CreateEmployeeDialog from '@/views/employee/components/CreateEmployeeDialog.vue'
import Rescreen from '@/components/registration-status/rescreen.vue'
import ApplyVoterRegistration from '@/components/dialogs/ApplyVoterRegistration.vue'
import loader from "@/assets/images/loader.png";

export default {
  name: 'EmployeeRegistrationStatus',
  components: { Rescreen, CreateEmployeeDialog, SuspendItem, ApplyVoterRegistration },
  data() {
    return {
      loader: loader,
      employees: [],
      visibleItems: [],
      selectedEmployees: [],
      registrationStages: [],
      selectedStage: null,
      stageLoading: false,
      selectedMember: null,
      tableSearch: '',
      isTableLoading: false,
      tableOptions: {},
      tableTotalItems: 0,
      tableHeaders: [
        { text: 'Name', align: 'start', value: 'fullName' },
        { text: 'Eligibility', align: 'center', value: 'ineligible', sortable: false},
        { text: 'Company', align: 'start', value: 'company', width: '10%' },
        { text: 'Mailing Address', align: 'start', value: 'fullMailAddress', sortable: false },
        { text: 'Voter Registration Address', align: 'start', value: 'fullVoterAddress', sortable: false },
        { text: 'VUID', align: 'start', value: 'vuid', sortable: false },
        { text: 'Registration Stage', align: 'start', value: 'registrationStage', sortable: false, width: '10%' },
        { text: 'Last updated', align: 'start', value: 'registrationStageUpdatedAt', sortable: false, width: '8%' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      isEditDialogOpen: false,
      isEditLoading: false,
      selectedEmployee: {},
      isEmployeeUpdatingWithoutRescreen: false,
      isEmployeeUpdatingWithRescreen: false,
      isRescreenDialogOpen: false,
      rescreenData: [],
      rescreenSelectedLoading: false,
      changeableEmployeeId: null,
      isSuspendDialogOpen: false,
      itemsToSuspend: [],
      setTimeoutTimer: null,
      isApplyVoterAddressDialogOpen: false,
      applyVoterAddressItems: [],
      applyVoterSelectedLoading: false,
      // scrollTop: null
    }
  },
  computed: {
    ...mapState({
      members: s => s.adminStore.members
    })
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.getEmployees()
      },
      deep: true
    }
  },
  async created() {
    await Promise.allSettled([
      this.getCounties(),
      this.getAllMembers(),
      this.getRegistrationStages()
    ])
  },
  methods: {
    ...mapActions({
      getRegistrationEmployees: 'employeeStore/getRegistrationEmployees',
      getEmployeeInfoToEdit: 'employeeStore/getEmployeeInfoToEdit',
      suspend: 'employeeStore/enableDisableMultipleEmployees',
      overwriteVoterAddress: 'employeeStore/overwriteVoterRegistrationAddress',
      editEmployeeByAdminWithoutRescreen: 'employeeStore/editEmployeeByAdminWithoutRescreen',
      editEmployeeWithRescreen: 'employeeStore/rescreenAddress',
      getCounties: 'communicationStore/getCounties',
      getAllMembers: 'adminStore/getMembers',
      getStages: 'employeeStore/getRegistrationStage',
      setStage: 'employeeStore/postRegistrationStatus'
    }),
    async getEmployees() {
      this.employees = []
      // const oldVisibleItems = this.visibleItems
      this.visibleItems = []
      this.isTableLoading = true
      const { itemsPerPage, page, sortBy, sortDesc } = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        search: this.tableSearch || null,
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null,
        registration_stage_id: this.selectedStage,
        company_id: this.selectedMember
      }
      const { data, meta: { total } } = await this.getRegistrationEmployees(params)
      this.tableTotalItems = total
      this.employees = data
      this.visibleItems = this.employees
      // this.visibleItems = oldVisibleItems.length ? this.employees.slice(0, oldVisibleItems.length) : this.employees.slice(0, 50)
      this.isTableLoading = false
    },
    async updateEmployeeWithoutRescreen(data) {
      this.isEmployeeUpdatingWithoutRescreen = true
      const dataToSend = {
        id: data.id,
        firstName: data.firstName,
        voter_county_id: data.voterCounty,
        secondName: data.secondName,
        lastName: data.lastName,
        userName: data.userName,
        email: data.email,
        secondaryEmail: data.secondaryEmail,
        homePhone: data.homePhone,
        workPhone: data.workPhone,
        mobilePhone: data.mobilePhone,
        mailAddress: data.mailAddress,
        mailCity: data.mailCity,
        mailState: data.mailState,
        mailZip: data.mailZip,
        voterAddress: data.voterAddress,
        voterCity: data.voterCity,
        voterState: data.voterState,
        voterZip: data.voterZip,
        county_id: data.county,
        vuid: data.vuid,
        birthDate: data.birthDate,
        employeeRoleId: data.hierarchy,
        reportsTo: data.reportsTo,
        member_id: data.memberId,
        inelegible: data.inelegible,
        location: data.location,
        department: data.department,
        eligibilityDetailId: data.inelegible ? data.eligibilityDetailId : null
      }
      await this.editEmployeeByAdminWithoutRescreen(dataToSend)
      this.isEmployeeUpdatingWithoutRescreen = false
      this.isEditDialogOpen = false
      await this.getEmployees()
      this.scrollToElAfterUpdate(data.id)
    },
    async updateEmployeeWithRescreen(data) {
      this.isEmployeeUpdatingWithRescreen = true
      this.changeableEmployeeId = data.id
      const res = await this.editEmployeeWithRescreen([data.id])
      this.isEmployeeUpdatingWithRescreen = false
      this.isEditDialogOpen = false
      if (res.data.length) {
        this.rescreenData = res.data
        this.isRescreenDialogOpen = true
      } else {
        await this.$store.dispatch('snackbar', {
          show: true,
          color: 'success',
          message: res.message[0]
        })
      }
      await this.getEmployees()
      this.scrollToElAfterUpdate(data.id)
    },
    async openEditDialog(employeeId) {
      this.changeableEmployeeId = employeeId
      this.isEditLoading = true
      const res = await this.getEmployeeInfoToEdit(employeeId)
      this.selectedEmployee = {
        id: res.id,
        firstName: res.firstName,
        secondName: res.secondName,
        lastName: res.lastName,
        birthDate: res.birthDate,
        homePhone: res.homePhone,
        workPhone: res.workPhone,
        mobilePhone: res.mobilePhone,
        county: res.county.value ? res.county.value : null,
        mailAddress: res.mailAddress,
        mailCity: res.mailCity,
        mailState: res.mailState,
        mailZip: res.mailZip,
        voterAddress: res.voterAddress,
        voterCity: res.voterCity,
        voterState: res.voterState,
        voterZip: res.voterZip,
        voterCounty: res.voterCounty.value ? res.voterCounty.value : null,
        vuid: res.vuid,
        department: res.department,
        location: res.location,
        email: res.email,
        secondaryEmail: res.secondaryEmail,
        memberId: res.memberId,
        reportsTo: res.reportsTo.id,
        hierarchy: res.hierarchy.id,
        inelegible: res.inelegible,
        eligibilityDetailId: res.inelegible ? res.eligibilityDetailId : null
      }
      this.isEditLoading = false
      this.isEditDialogOpen = true
    },
    async updateAfterResolve() {
      await this.getEmployees()
      this.scrollToElAfterUpdate(this.changeableEmployeeId)
    },
    searchEmployees() {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(() => this.tableOptions = { ...this.tableOptions, page: 1 }, 500)
    },
    async setRegistrationStatus(itemId, e) {
      this.changeableEmployeeId = itemId
      this.stageLoading = true
      await this.setStage({
        employee_id: itemId,
        stageId: {
          registration_stage_id: e
        }
      })
      this.stageLoading = false
      await this.getEmployees()
      this.scrollToElAfterUpdate(itemId)
    },
    openSuspendDialog(items) {
      this.itemsToSuspend = items
      this.isSuspendDialogOpen = true
    },
    async suspendEmployees() {
      await this.suspend({ employees: this.itemsToSuspend })
      const elToScroll = this.itemsToSuspend[this.itemsToSuspend.length - 1].id
      this.isSuspendDialogOpen = false
      await this.getEmployees()
      this.scrollToElAfterUpdate(elToScroll)
      this.selectedEmployees = []
    },
    async rescreenSelected() {
      const ids = this.selectedEmployees.map(el => el.id)
      this.rescreenSelectedLoading = true
      const res = await this.editEmployeeWithRescreen(ids)
      this.rescreenSelectedLoading = false
      if (res.data.length) {
        this.rescreenData = res.data
        this.isRescreenDialogOpen = true
      }
      this.selectedEmployees = []
      await this.getEmployees()
      this.scrollToElAfterUpdate(ids[0])
    },
    async getRegistrationStages() {
      this.registrationStages = await this.getStages()
    },
    exportStage() {
      location.href = this.selectedMember
        ? `${ HTTP.defaults.baseURL }business/employee-registration-status-exports?company=${ this.selectedMember }`
        : `${ HTTP.defaults.baseURL }business/employee-registration-status-exports`
    },
    scrollToElAfterUpdate(elId) {
      this.$nextTick(() => {
        const refsKeys = Object.keys(this.$refs)
        const modifiedElIndex = refsKeys.indexOf(`list-item${ elId }`)
        const elToScroll = this.$refs[refsKeys[modifiedElIndex]] || this.$refs[refsKeys[modifiedElIndex + 1]]
        const offsetTop = elToScroll
          ? window.scrollY + elToScroll.getBoundingClientRect().top - (elToScroll.offsetHeight + 100)
          : document.body.scrollHeight
        this.$refs.tableRef.scrollTo({ top: offsetTop, behavior: 'smooth' })
      })
    },
    openApplyVoterAddress(items) {
      this.applyVoterAddressItems = items
      this.isApplyVoterAddressDialogOpen = true
    },
    async processOverwriteVoterAddress() {
      this.applyVoterSelectedLoading = true
      const employee_ids = this.applyVoterAddressItems.map(el => el.id)
      await this.overwriteVoterAddress({employee_ids: employee_ids})
      const elToScroll = this.applyVoterAddressItems[this.applyVoterAddressItems.length - 1].id
      this.isApplyVoterAddressDialogOpen = false 
      await this.getEmployees()
      this.scrollToElAfterUpdate(elToScroll)
      this.selectedEmployees = []
      this.applyVoterAddressItems = []
      this.applyVoterSelectedLoading = false
    }
  }
}
</script>

<style lang='scss' scoped>
.table-wrapper {
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
  ::v-deep .v-data-table__wrapper {
    padding-bottom: 80px;
  }
  ::v-deep .v-data-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}
.full-name {
  color: #101828;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  font-size: 0.875rem;
}
::v-deep .text-start {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #667085;
}
.export-btn {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
}
</style>
